import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'services/apiService';
import API_URL from 'assets/config';

const base_url = API_URL;

export const getInvoiceStatistics = createAsyncThunk('account-invoice-statistics', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}billing/get_invoice_statistics`, payload);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

export const getAwaitingInvoice = createAsyncThunk('awaiting-invoice', async () => {
    try {
        const { data } = await ax.get(`${base_url}billing/waiting_for_invoice_new`);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response.data;
        }
    }
});

export const getInvoiceData = createAsyncThunk('review-invoice', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}billing/get_invoice_grid_new`, payload);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});
export const getInvoiceInfo = createAsyncThunk('get-invoice-info', async (id: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.get(`${base_url}billing/invoice_info/${id}`);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const updateInvoicePrice = createAsyncThunk('update-invoice-price', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}billing/invoice_price_update`, payload);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const generateCustomInvoice = createAsyncThunk('generate-custom-invoice', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}billing/generate_custom_invoice`, payload);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
export const proceedToInvPayment = createAsyncThunk('procedd-to-invoice-payment', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}billing/pay_inv_payment`, payload);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const approveInvoice = createAsyncThunk('approve-invoice', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}billing/approve_invoice_update`, payload);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

export const updatePaymentStatus = createAsyncThunk('update-payment-status', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}billing/update_payment`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

//invoice grpah
export const getInvoiceGraph = createAsyncThunk('get-invoice-graph', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}account/invoice_statistic`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

//credit payment detial 
export const updateCreditPaymentStatus = createAsyncThunk('update-credit-payment-status', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}billing/update_payment_detail`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getTransactionDetails = createAsyncThunk('get-transaction-history', async (id:number, { rejectWithValue }) => {
    try {
        const { data } = await ax.get(`${base_url}billing/invoice_transaction/${id}`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});