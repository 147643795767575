import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'services/apiService';
import API_URL from 'assets/config';

const base_url = API_URL;

export const userLogin = createAsyncThunk('user-login', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}auth/login`, payload);
        // localStorage.setItem('token', true.toString());
        localStorage.setItem('userid', data?.data?.userid);
        sessionStorage.setItem('userid', data?.data?.userid);
        sessionStorage.setItem('role', data?.data?.role);
        sessionStorage.setItem('accountid', data?.data?.user_acctid);
        sessionStorage.setItem('firstname', data?.data?.firstname);
        sessionStorage.setItem('lastname', data?.data?.lastname);
        sessionStorage.setItem('username', data?.data?.username);
        sessionStorage.setItem('password', data?.data?.password);
        if (!data?.data?.mfa_enabled) {
            localStorage.setItem('token', true.toString());
        }
        return data;
        
    } catch (error: any) {
        console.log('errir', error);
        if (error.code === "ECONNABORTED" || error.code === "ERR_NETWORK") {
            return rejectWithValue({ msg: "Timeout" });
        }
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

//auth/password_reset
export const SendEmail = createAsyncThunk('auth-send-email', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}auth/password_reset`, payload);
        // store user's token in local storage
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

//change password
export const ChangePass = createAsyncThunk('auth-change-pass', async (payload: any, { rejectWithValue }) => {
    try {
        // configure header's Content-Type as JSON
        const { data } = await ax.put(`${base_url}auth/change-password`, payload);
        // store user's token in local storage
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

//confirm reset password
export const confirmPass = createAsyncThunk('auth/confirm-pass', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}auth/password_reset/confirm/`, payload);
        // store user's token in local storage
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

//verify token
export const VerifyToken = createAsyncThunk('auth/verify-token', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}auth/reset-password/verify-token`, payload);
        // store user's token in local storage
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

//user profilecall
export const getUserProfile = createAsyncThunk('account-user-profile', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}account/UserProfileCall`, payload);
        // store user's token in local storage
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

export const changeDefaultPass = createAsyncThunk('default-pass-user-profile', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}auth/login_auth`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

export const confirmPassword = createAsyncThunk('auth/confirm-password', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}auth/password_reset/password_reset_confirm`, payload);
        // store user's token in local storage
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

//mfa authentication
export const mfaCodeVerify = createAsyncThunk('mfa-verify', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}auth/mfa_verify`, payload);
        if (data?.status === 'success') {
            localStorage.setItem('token', true.toString());
        }
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

//mfa reset 
export const mfaCodeReset = createAsyncThunk('mfa-reset', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}auth/mfa_reset`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

// email authentication
export const sendEmailCode = createAsyncThunk('send-email', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}auth/login_with_otp`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

// email authentication verification
export const verifyEmailCode = createAsyncThunk('email-verify', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}auth/validate_otp`, payload);
        if (data?.status === 'success') {
            localStorage.setItem('token', true.toString());
        }
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

// logout api
export const userLogout = createAsyncThunk('logout', async () => {
    try {
        const { data } = await ax.get(`${base_url}auth/logout`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.message;
        }
    }
});

// account/saveAccount_public 'account/saveAccount_public'
export const signUpAccount = createAsyncThunk('signup-new-account', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}account/saveAccount_public`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});