import { useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';
import LoginPage from './components/auth/LoginPage';
import ForgotPassword from 'components/auth/ForgotPassword';
import ResetPassword from 'components/auth/ResetPassword';
import PrivateRoute from 'components/routes/PrivateRoute';
import EdfViewer from 'components/edfViewer/EdfViewer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/styles/app.scss';
import 'assets/styles/customtheme.scss';
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import 'assets/styles/resolution.scss';
import { useSelector } from 'react-redux';
import ChangePassword from 'components/auth/ChangePassword';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import Mfa from 'components/auth/Mfa';
import CustomerAgreement from 'components/auth/CustomerAgreement';
import { useDispatch } from 'react-redux';
import { userLogout } from 'services/actions/authAction';
import SignupAccount from 'components/auth/SignupAccount';
const EmailSentForm = loadable(() => import('components/auth/EmailSentForm'), { fallback: <LoadingIndicator /> });
const Page404 = loadable(() => import('components/auth/Page404'), { fallback: <LoadingIndicator /> });
const PatientIntakeForm = loadable(() => import('components/PatientIntakeForm'), { fallback: <LoadingIndicator /> });

function App() {
    const location = useLocation();
    const dispatch = useDispatch();
    const isAuth = localStorage.getItem('token') === "true"
    const { userInfo } = useSelector((state: any) => state.auth);
    const hasUserInfo = userInfo && Object.keys(userInfo).length > 0;
    const isMfaEnabled = userInfo && userInfo?.data?.mfa_enabled === false;
    const [lastActiveTime, setLastActiveTime] = useState(Date.now());
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const TIMEOUT = 30 * 60 * 1000; // 30 minutes in milliseconds
    const [isOffline, setIsOffline] = useState(!navigator.onLine);

    useEffect(() => {
        const handleOffline = () => setIsOffline(true);
        const handleOnline = () => { setIsOffline(false); window.location.reload() };

        window.addEventListener("offline", handleOffline);
        window.addEventListener("online", handleOnline);

        return () => {
            window.removeEventListener("offline", handleOffline);
            window.removeEventListener("online", handleOnline);
        };
    }, []);

    const logout = () => {
        setIsLoggedOut(true);
        window.location.href = '/';
        dispatch(userLogout() as any);
        localStorage.clear();
    };

    const resetTimer = () => {
        setLastActiveTime(Date.now());
    };

    useEffect(() => {
        if (location.pathname == '/login') {
            return;
        }
        const handleUserActivity = () => {
            resetTimer();
        };

        const events = ['mousemove', 'keydown', 'click'];

        events.forEach(event => window.addEventListener(event, handleUserActivity));

        const checkInactivity = setInterval(() => {
            if (Date.now() - lastActiveTime >= TIMEOUT) {
                logout();
                clearInterval(checkInactivity);  // Clear the interval once logged out
            }
        }, 1000); // Check every second

        return () => {
            events.forEach(event => window.removeEventListener(event, handleUserActivity));
            clearInterval(checkInactivity);
        };

    }, [lastActiveTime]);

    if (isLoggedOut) {
        return <div>You have been logged out due to inactivity.</div>;
    }

    return (
        <div className="App">
            {isOffline ? <div className="fixed top-0 left-0 w-full bg-danger text-white text-center py-2">
                ⚠️ No Internet Connection. Please check your network.
            </div> : ""}
            <Routes>
                <Route path="/login" element={<LoginPage />}></Route>
                <Route path="/reset-password" element={<ForgotPassword />}></Route>
                <Route path="/email-sent" element={<EmailSentForm />}></Route>
                <Route path="/change-password/:id" element={<ChangePassword />} />
                <Route path="/page404" element={<Page404 />}></Route>
                <Route path="/patient-form/:id" element={<PatientIntakeForm />}></Route>
                <Route path="/change-default-password" element={<ResetPassword />}></Route>
                <Route path="/" element={<Navigate replace to="/login" />} />
                <Route path="/edf" element={<EdfViewer />}></Route>
                <Route path="/user-verification" element={<Mfa />}></Route>
                <Route path="/customer-agreement" element={<CustomerAgreement />}></Route>
                <Route path='/signup-account' element={<SignupAccount />} />
                {isAuth || (hasUserInfo || isMfaEnabled) ? <Route path="*" element={<PrivateRoute />} /> : <Route path="*" element={<Navigate to="/login" />} />}
            </Routes>
        </div>
    );
}

export default App;
