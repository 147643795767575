
export default function LoadingIndicator() {
    return (
        <div className="loader-wrapper">
            <div className="loader-indicator">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}